import settingsService from '@/services/chatSettingsService';

export default {
  name: 'MessagingChannelsComponent',
  components: {},
  data() {
    return {
      loading: false,

      existingChannels: null
    };
  },

  methods: {
    getChannelImage(channel) {
      if (channel == 'instagram') {
        return require('@/assets/images/messaging-channels/insta.svg');
      } else if (channel == 'whatsApp') {
        return require('@/assets/images/messaging-channels/whatsapp.svg');
      }
    },

    async fetchInstalledChannels() {
      try {
        this.loading = true;
        let response = await settingsService.getInstalledChannels();
        this.existingChannels = response.data;
      } catch (err) {
        this.errorToast('Failed to fetch details. Please contact support.');
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    onDeleteChannelButtonClick(index) {
      // Show Confirmation Dialog.
      let channel = this.existingChannels[index];
      console.log(channel);

      var component = this;
      this.$swal({
        title: `Delete Channel?`,
        text: `If channel deleted, you will not be able to send or receive messages from the channel. Existing tickets of this channel will be not deleted.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Delete'
      }).then(() => {
        component.deleteChannel(index, channel);
      });
    },

    async deleteChannel(index, channel) {
      try {
        this.loading = true;

        await settingsService.deleteChannel({
          channel_id: channel.id,
          channel_type: channel.channel_type
        });

        this.successToast('Channel deleted successfully.');
        this.fetchInstalledChannels();
      } catch (err) {
        this.errorToast('Failed to delete channel. Please contact support.');
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },

  mounted() {
    this.fetchInstalledChannels();
  }
};
