<template>
  <div class="mt-5 mb-5 px-2 sm:px-6 lg:px-2">
    <div class="px-4 sm:px-6 lg:px-8" style="margin: auto; max-width: 1200px">
      <!-- Header -->
      <div class="flex mb-4">
        <div class="flex-1">
          <div class="pt-0 text-2xl font-medium">
            <div class="">Inbox Channels</div>
            <div class="text-xs pt-1 font-normal text-gray-500">Setup channels from where customers can communicate with you.</div>
          </div>
        </div>
        <div class="pt-1">
          <router-link to="/settings/messaging-channels/add">
            <el-button type="primary" icon="el-icon-plus"> Add Inbox Channel</el-button>
          </router-link>
        </div>
      </div>

      <!-- Loader -->
      <div v-if="loading" class="w-full bg-white border rounded-lg mt-2 overflow-hidden" style="height: 60vh" v-loading="true"></div>

      <!-- Channels List -->
      <div v-else v-for="(channel, index) in existingChannels" :key="index" class="mx-auto mb-3 bg-white relative overflow-hidden border rounded-lg flex justify-between px-4 py-3">
        <div class="flex min-w-0 gap-x-4 w-full">
          <!-- icon -->
          <div class="" style="font-size: 37px">
            <i v-if="channel.channel_type == 'instagram'" class="fa-brands fa-square-instagram instagram_color"></i>
            <i v-else-if="channel.channel_type == 'whatsApp'" class="fa-brands fa-square-whatsapp" style="color: #2cb706"></i>
            <i v-else-if="channel.channel_type == 'facebook_messenger'" class="fa-brands fa-facebook-messenger" style="color: #047df8"></i>
          </div>

          <div class="pt-0 text-lg leading-6 text-gray-900 flex-1">
            <div class="text-sm text-gray-500">{{ getChannelDisplayText(channel.channel_type) }}</div>
            {{ channel.provider_name }}
          </div>

          <!-- Right Side -->
          <div class="pt-2" v-if="channel.channel_type != 'whatsApp'">
            <el-tooltip class="item" effect="dark" content="Delete Channel" placement="left">
              <el-button type="danger" size="small" icon="el-icon-delete" plain circle @click="onDeleteChannelButtonClick(index)"></el-button>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./messagingChannels.scss"></style>

<script>
import messagingChannelsComponent from './messagingChannelsComponent';
export default messagingChannelsComponent;
</script>
